import styled from "styled-components";
import { Button } from "./components";

const Message = styled.h2`
  margin-bottom: 24px;
`;

type ConfirmationProps = {
  onNewForm: () => void;
  email: string;
};

function Confirmation({ onNewForm, email }: ConfirmationProps) {
  return (
    <>
      <Message>
        Bookin vous remercie pour votre confiance. Si vous voyez ce message,
        c'est que nous avons bien reçu votre demande. Nous vous enverrons les
        liens de téléchargement sur {email} au fur et à mesure que vos ouvrages
        seront disponibles.
      </Message>
      <Button onClick={onNewForm}>Remplir un nouveau formulaire</Button>
    </>
  );
}

export default Confirmation;
