import type { RememberedFields } from "./RequestForm/RequestForm";

export type FormData = RememberedFields & {
  books: string[];
};

export type Book = {
  bookId: string;
  status?:
    | "available"
    | "pending"
    | "unavailable"
    | "outdated"
    | "unknown"
    | "requested";
  comment?: string;
  requestedAt?: Date;
};

export type ExtendedBook = Omit<Book, "status"> & {
  status?: Book["status"] | "duplicate";
};

export type Request = {
  email: string;
  bookId: string;
  childFirstname: string;
  childLastname: string;
  receptionEmail: string | null;
  requestedAt: Date;
};

export type RequestCreation = Omit<Request, "requestedAt">;

export type RequestError = {
  invalidEmails: string[];
  invalidBooks: string[];
  failedRequests: { email: string; bookId: string; error: string }[];
};

export type User = {
  email: string;
  firstname: string;
  lastname: string;
  postalCode: string;
  phoneNumber: string;
  memberUntil?: Date;
};

export type ExtendedRequest = Request &
  Pick<FormData, "firstname" | "lastname" | "phoneNumber"> &
  Pick<Book, "status">;

const test = process.env.NODE_ENV === "development";
const host = test ? "localhost" : "https://form.bookin.lu";

// eslint-disable-next-line @typescript-eslint/no-empty-function
let jwtErrorHandler = () => {};
let jwtToken = localStorage.getItem("jwt") || "";
export const setJWTErrorHandler = (onJWTError: () => void) => {
  jwtErrorHandler = onJWTError;
};

export const setJWTToken = (jwt: string) => {
  jwtToken = jwt;
  localStorage.setItem("jwt", jwt);
};

export const login = async (username: string, password: string) => {
  const response = await fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  if (response.status === 401) {
    jwtErrorHandler();
    setJWTToken("");
  }
  if (response.status >= 400) throw new Error(response.statusText);
  const data = await response.json();
  setJWTToken(data.token);
  return data.token;
};

export const submitForm = async (data: FormData) => {
  const response = await fetch(`${host}/api/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status >= 400)
    throw new Error(response.statusText, { cause: await response.json() });
};

export const getBookStatus = async (bookId: string): Promise<Book> => {
  const response = await fetch(`${host}/api/book?bookId=${bookId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 404) return { bookId, status: "unknown" };
  if (response.status >= 400) throw new Error(response.statusText);
  const { status, comment, requestedAt } = (await response.json()) as Book;
  return { bookId, status, comment, requestedAt };
};

export const getBookStatusForUser = async (
  bookId: string,
  email: string
): Promise<ExtendedBook> => {
  const response = await fetch(
    `${host}/api/book?bookId=${bookId}&email=${encodeURIComponent(email)}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );
  if (response.status === 404) return { bookId, status: "unknown" };
  if (response.status === 429) return { bookId, status: "duplicate" };
  if (response.status >= 400) throw new Error(response.statusText);
  const { status, comment, requestedAt } = (await response.json()) as Book;
  return { bookId, status, comment, requestedAt };
};

export const getAllRequests = async () => {
  const response = await fetch(`${host}/api/requests`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  const requests = (await response.json()) as ExtendedRequest[];
  return requests;
};

export const getAllBooks = async () => {
  const response = await fetch(
    `${host}/api/books?status=pending&status=requested`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );
  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  const books = (await response.json()) as Book[];
  return books;
};

export const submitBook = async (bookData: Book) => {
  const response = await fetch(`${host}/api/book`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(bookData),
  });
  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  return response;
};

export const uploadBook = async (file: File, requesters: string[]) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  formData.append("requesters", JSON.stringify(requesters));

  const response = await fetch(`${host}/api/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    body: formData,
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
};

export const getFreeSpace = async () => {
  const response = await fetch(`${host}/api/free-space`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  const spaceLeft = (await response.json()) as {
    space: string;
    cleanupDate: string;
  };
  return spaceLeft;
};

export const isElectron = () => {
  return (
    window &&
    window.process &&
    (window.process as unknown as { type: "renderer" }).type === "renderer"
  );
};

export type EmailId =
  | "confirm_email"
  | "book_status_update"
  | "book_link"
  | "deleted";
export const getEmail = async (id: EmailId) => {
  const response = await fetch(`${host}/api/email?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  const email = (await response.json()) as { subject: string; content: string };
  return email;
};

export const setEmail = async (id: string, subject: string, html: string) => {
  const response = await fetch(`${host}/api/email`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      subject,
      content: html,
    }),
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
};

export type FormId =
  | "exposition"
  | "membershipRequest"
  | "consent"
  | "consentQuestion"
  | "confirmed";
export const getForm = async (id: FormId) => {
  const response = await fetch(`${host}/api/form?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  const form = (await response.json()) as { content: string };
  return form;
};

export const setForm = async (id: string, html: string) => {
  const response = await fetch(`${host}/api/form`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      content: html,
    }),
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
};

export const isMember = async (email: string) => {
  const response = await fetch(
    `${host}/api/user?email=${encodeURIComponent(email)}`
  );
  if (response.status === 404) return false;
  else if (response.status >= 400) throw new Error(response.statusText);
  else return true;
};

export const getMember = async (email: string) => {
  const response = await fetch(`${host}/api/user/${encodeURI(email)}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 404) return null;
  else if (response.status >= 400) throw new Error(response.statusText);
  else return response.json();
};

export const updateMember = async (user: User) => {
  const response = await fetch(`${host}/api/user/${encodeURI(user.email)}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  if (response.status === 404) return null;
  else if (response.status >= 400) throw new Error(response.statusText);
  else return response.json();
};

export const addMembers = async (users: User[]) => {
  const response = await fetch(`${host}/api/users`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users }),
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
};

export const deleteMember = async (email: string) => {
  const response = await fetch(`${host}/api/user/${encodeURI(email)}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 404) return;
  else if (response.status >= 400) throw new Error(response.statusText);
};

/**
 * Sends book request data to the server to be processed and stored.
 * @param {RequestCreation[]} requests - An array of request objects.
 * @returns {Promise<void>} - A promise that resolves if the requests were successfully processed, or rejects with an error.
 * @throws {RequestError} - Details about the errors
 */
export const createRequests = async (requests: RequestCreation[]) => {
  const response = await fetch(`${host}/api/requests`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify({ requests }),
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw await response.json();
  return await response.json();
};

/**
 * Updates a configuration setting on the server.
 * @param {string} key The configuration key to update.
 * @param {string} value The new value for the configuration.
 * @returns {Promise<void>} A promise that resolves if the configuration was successfully updated, or rejects with an error.
 */
export const updateConfiguration = async (key: string, value: string) => {
  const response = await fetch(`${host}/api/configurations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify({ key, value }),
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
};

/**
 * Read a configuration setting from the server.
 * @param {string} key The configuration key to read.
 * @returns {Promise<{ key: string, value: string }>} A promise that resolves with the configuration details.
 */
export const getConfiguration = async (key: string) => {
  const response = await fetch(`${host}/api/configurations/${key}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  if (response.status === 401) jwtErrorHandler();
  if (response.status >= 400) throw new Error(response.statusText);
  return await response.json();
};
